import BigNumber from "bignumber.js"
import React, { useEffect, useState, useRef } from 'react';
import ReactPlayer from 'react-player';
import { Router, View, Link } from 'react-navi'
import { mount, route } from 'navi'
import SyntaxHighlighter from 'react-syntax-highlighter'
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs'
import './App.css';

const routes = mount({
  '/': route({ view: <HomePage /> }),
  '/codeRecordings': route({view: <ListPage />}),
  '/codeRecordings/:id': route(req => {
    return { view: <VideoPage id={req.params.id} videoPosition={'videoPosition' in req.params ? req.params.videoPosition : ''} /> }
  }),
  '/codeRecordings/:id/videoPositions/:position/code': route(req => {
    return { view: <CodePage id={req.params.id} position={req.params.position} />}
  })
})

function App() {
  return (
    <Router routes={routes}>
      <View />
    </Router>
  )
}

function HomePage() {
  return (
    <div>
      CodeRecording.com is under construction and not yet available.
    </div>
  )
}

function ListPage() {
  const [ codeRecordings, setCodeRecordings ] = useState([])
  useEffect(() => {
    fetch('https://api.coderecording.com/codeRecordings', {headers: {'Access-Control-Allow-Origin': '*'}})
      .then(result => result.json())
      .then(codeRecordings => setCodeRecordings(codeRecordings))
      .catch(error => console.log(error))
  }, [])

  const renderedCodeRecordings = codeRecordings.map(codeRecording =>
    <li key={codeRecording.id}><Link href={`/codeRecordings/${codeRecording.id}`}>{codeRecording.name}</Link></li>
  )

  return (
    <div>
      <ul>
        {renderedCodeRecordings}
      </ul>
    </div>
  )
}

function CodePage({ id, position }) {
  const [ files, setFiles ] = useState([])
  const [ file, setFile ] = useState({name: '', content: '', type: ''})

  useEffect(() => {
    fetch(`https://api.coderecording.com/codeRecordings/${id}/videoPositions/${position}`, {headers: {'Access-Control-Allow-Origin': '*'}})
      .then(result => result.json())
      .then(result => {
        setFiles(result.files)
        if ('fileInFocus' in result) {
          getFileHandler(result.fileInFocus)()
        }
      })
      .catch(error => console.log(error))
  }, [id, position])

  const getFileHandler = filePath => {
    return () => {
      fetch(`https://api.coderecording.com/codeRecordings/${id}/videoPositions/${position}/files?path=${filePath}`, {headers: {'Access-Control-Allow-Origin': '*'}})
        .then(result => result.json())
        .then(file => setFile(file))
        .catch(error => console.log(error))
    }
  }

  const renderedFiles = files.map(filePath =>
    <tr key={filePath}><td><a onClick={getFileHandler(filePath)}>{filePath}</a></td></tr>
  )

  const downloadZip = event => {
    window.open(`https://api.coderecording.com/codeRecordings/${id}/videoPositions/${position}/zip`)
  }

  return (
    <div style={{margin: 'auto', width: '75%'}}>
      <h1>Hello World in Kotlin</h1>
      <Link href={`/codeRecordings/${id}?videoPosition=${position}`}>Back</Link>
      <button onClick={downloadZip} style={{float: 'right'}}>Download</button>
      <table height='100%'>
        <tbody height='100%'>
          <tr valign='top' height='100%'>
            <td>
              Files:
              <table>
                <tbody>{renderedFiles}</tbody>
              </table>
            </td>
            <td width='100%' height='100%'>
              File: {file.name}
              {file.type == 'Binary' ? (
                <div>Binary File</div>
              ) : (
                <SyntaxHighlighter style={docco} height='100%'>
                  {file.content}
                </SyntaxHighlighter>
              )}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

function VideoPage({ id, videoPosition }) {
  const [ codeRecording, setCodeRecording ] = useState({})
  const [ search, setSearch ] = useState('')
  const [ adjustment, setAdjustment] = useState('1000')
  const [ searchResultVideoPositions, setSearchResultVideoPositions] = useState([])
  const [ videoState, setVideoState ] = useState({
    playedSeconds: 0,
    played: 0
  })
  const [ searchDisplay, setSearchDisplay ] = useState('')

  // Reference to video player
  const playerRef = useRef(null)

  // Load codeRecording at start
  useEffect(() => {
    fetch(`https://api.coderecording.com/codeRecordings/${id}`, {headers: {'Access-Control-Allow-Origin': '*'}})
      .then(result => result.json())
      .then(codeRecording => setCodeRecording(codeRecording))
      .catch(error => console.log(error))
  }, [id])

  // Update video state as video plays
  const handleProgress = state => {
    setVideoState(state)
  }

  const goToVideoPosition = () => {
    if (videoPosition) {
      playerRef.current.seekTo(new BigNumber(videoPosition).dividedBy('1000').toNumber(), 'seconds')
    }
  }

  // Update search state when search input is modified
  const updateSearch = event => {
    setSearch(event.target.value)
  }

  const updateAdjustment = event => {
    setAdjustment(event.target.value)
  }

  // When search state changes, get search results from the backend
  useEffect(() => {
    if (search === '') {
      setSearchResultVideoPositions([])
    } else {
      fetch(`https://api.coderecording.com/codeRecordings/${id}/videoPositions?search=${search}`, {headers: {'Access-Control-Allow-Origin': '*'}})
        .then(result => result.json())
        .then(videoPositions => setSearchResultVideoPositions(videoPositions))
        .catch(error => console.log(error))
    }
  }, [id, search])

  // Algorithm for determining search display
  const buildSearchDisplay = (currentTime, searchResultVideoPositions) => {
    if (currentTime === null) {
      currentTime = new BigNumber('0')
    } else {
      currentTime = new BigNumber(currentTime).multipliedBy('1000')
    }
    
    if (searchResultVideoPositions.length === 0) {
      return '0 results'
    } else if (currentTime.isLessThan(new BigNumber(searchResultVideoPositions[0]))) {
      return `Position before 1 of ${searchResultVideoPositions.length} results`
    } else {
      for (var i = 0; i < searchResultVideoPositions.length; i++) {
        let videoPosition = new BigNumber(searchResultVideoPositions[i])
        if (currentTime.isEqualTo(videoPosition)) {
          return `Position at ${i+1} of ${searchResultVideoPositions.length} results`
        } else if (currentTime < videoPosition) {
          return `Position between ${i} and ${i+1} of ${searchResultVideoPositions.length} results`
        }
      }
      return `Position after ${searchResultVideoPositions.length} of ${searchResultVideoPositions.length} results`
    }
  }

  // When searchResults or videoState changes, update the searchDisplay
  useEffect(() => {
    if (search.length === 0) {
      setSearchDisplay('')
    } else {
      setSearchDisplay(buildSearchDisplay(videoState.playedSeconds, searchResultVideoPositions))
    }
  }, [search, searchResultVideoPositions, videoState])

  const moveToPrevSearchResult = event => {
    if (searchResultVideoPositions.length > 0) {
      let currentTime = new BigNumber(videoState.playedSeconds).multipliedBy('1000')
      for (let i = searchResultVideoPositions.length - 1; i >=0; i--) {
        let searchResultVideoPosition = new BigNumber(searchResultVideoPositions[i])
        if (currentTime.isGreaterThan(searchResultVideoPosition)) {
          let newPosition = searchResultVideoPosition.dividedBy('1000').toNumber()
          console.log(`seekTo: ${newPosition}`)
          playerRef.current.seekTo(newPosition, 'seconds')
          return
        }
      }
      let newPosition = new BigNumber(searchResultVideoPositions[searchResultVideoPositions.length - 1])
        .dividedBy('1000').toNumber()
      console.log(`seekTo: ${newPosition}`)
      playerRef.current.seekTo(newPosition, 'seconds')
    }
  }

  const moveToNextSearchResult = event => {
    if (searchResultVideoPositions.length > 0) {
      let currentTime = new BigNumber(videoState.playedSeconds).multipliedBy('1000')
      for (let i = 0; i < searchResultVideoPositions.length; i++) {
        let searchResultVideoPosition = new BigNumber(searchResultVideoPositions[i])
        if (currentTime.isLessThan(searchResultVideoPosition)) {
          let newPosition = searchResultVideoPosition.dividedBy('1000').toNumber()
          console.log(`seekTo: ${newPosition}`)
          playerRef.current.seekTo(newPosition, 'seconds')
          return
        }
      }
      let newPosition = new BigNumber(searchResultVideoPositions[0])
        .dividedBy('1000').toNumber()
      console.log(`seekTo: ${newPosition}`)
      playerRef.current.seekTo(newPosition, 'seconds')
    }
  }

  const adjustBack = event => {
    let currentTime = new BigNumber(videoState.playedSeconds).multipliedBy('1000')
    let newPosition = BigNumber.max(
      currentTime.minus(adjustment).dividedBy('1000'),
      new BigNumber('0')
    ).toNumber()
    playerRef.current.seekTo(newPosition, 'seconds')
  }

  const adjustForward = event => {
    let currentTime = new BigNumber(videoState.playedSeconds).multipliedBy('1000')
    let newPosition = BigNumber.min(
      currentTime.plus(adjustment).dividedBy('1000'),
      new BigNumber(playerRef.current.getDuration())
    ).toNumber()
    playerRef.current.seekTo(newPosition, 'seconds')
  }

  return (
    <div style={{margin: 'auto', width: '75%'}}>
      <h1>{codeRecording.name}</h1>
      <div className='player-wrapper'>
        <ReactPlayer 
          ref={playerRef}
          className='react-player'
          url={codeRecording.videoUrl}
          width='100%'
          height='100%'
          onReady={goToVideoPosition}
          onProgress={handleProgress}
        />
      </div>
      <Link
        href={`/codeRecordings/${id}/videoPositions/${new BigNumber(videoState.playedSeconds).multipliedBy('1000').toNumber()}/code`}
        >
        Src
      </Link>
      &nbsp;
      <input type='text' placeholder='Search' value={search} onChange={updateSearch} />
      {search.length > 0 ? (
        <span>
          <button
            disabled={searchResultVideoPositions.length === 0}
            onClick={moveToPrevSearchResult}
            >
              {'<'}
          </button>
          <button
            disabled={searchResultVideoPositions.length === 0}
            onClick={moveToNextSearchResult}
            >
              {'>'}
          </button>
          &nbsp;
          {searchDisplay}
        </span>
      ) : (
        <span></span>
      )}
      {/* <input type='text' placeholder='Adjust' value={adjustment} onChange={updateAdjustment} />
      <button onClick={adjustBack}>{'<'}</button>
      <button onClick={adjustForward}>{'>'}</button>
      {videoState.playedSeconds} */}
    </div>
  );
}

export default App;
